import { FC, useEffect, useState } from 'react'

import SessionExpiredModal from 'src/components/SessionExpiredModal/SessionExpiredModal'
import * as Providers from 'src/Providers'

const validateToken = (token: string): boolean | null => {
  try {
    if (!token) {
      return null
    }

    const tokenData = JSON.parse(atob(token.split('.')[1]))
    const expirationTime = tokenData.exp * 1000
    return Date.now() < expirationTime
  } catch (error) {
    return false
  }
}

const CognitoTokenChecker: FC = () => {
  const auth = Providers.useAuth?.()
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)

  useEffect(() => {
    const checkInterval = setInterval(() => {
      auth?.getToken().then((token) => {
        const isValid = validateToken(token)
        if (isValid === false) {
          setShowSessionExpiredModal(true)
        }
      })
    }, 60000)

    return () => clearInterval(checkInterval)
  }, [auth])

  return (
    <SessionExpiredModal
      isOpen={showSessionExpiredModal}
      buttonTestId="app-reload-page-btn"
    />
  )
}

export default CognitoTokenChecker
