import { createFragmentRegistry } from '@apollo/client/cache'

import {
  LandlordFragment,
  LandlordEmailFragment,
  LandlordFlaggedEmailWithoutResolversFragment,
} from 'src/components/SentimentAnalysis/Fragments'

import { ACL_FIELDS_FRAGMENT } from './fragments/ACLFields'
import { CHANGELOG_FIELDS_FRAGMENT } from './fragments/ChangelogFields'
import { DUPLICATING_BASEROW_APPLICATION_FIELDS } from './fragments/DuplicatingBaserowApplicationFields'
import { IMPROVER_GOAL_MEMBER_FIELDS_FRAGMENT } from './fragments/ImproverGoalMemberFields'
import { LEARNER_CATEGORY_FIELDS_FRAGMENT } from './fragments/LearnerCategoryFields'

// When registering a new fragment, please follow these steps:
// 1) Add a fragment file i.e. ./fragments/MyFragment.ts
// 2) Add the fragment to the fragmentRegistry below
// 3) Add your type policy to web/src/apolloCache/typePolicy/typePolicies.ts

// NOTE: Sentiment (Landlord...) fragments only added for registration purposes (not cache)
export const fragmentRegistry = createFragmentRegistry(gql`
  ${LEARNER_CATEGORY_FIELDS_FRAGMENT}
  ${IMPROVER_GOAL_MEMBER_FIELDS_FRAGMENT}
  ${ACL_FIELDS_FRAGMENT}
  ${LandlordFragment}
  ${LandlordEmailFragment}
  ${LandlordFlaggedEmailWithoutResolversFragment}
  ${CHANGELOG_FIELDS_FRAGMENT}
  ${DUPLICATING_BASEROW_APPLICATION_FIELDS}
`)
