import { useEffect } from 'react'

import * as Sentry from '@sentry/browser'
import { ReplayFrameEvent } from '@sentry/browser'
import { HUBS_URL } from 'api/src/common/enums'
import {
  isGHActionFn,
  isLocalFn,
  isProductionFn,
} from 'api/src/common/environment'

interface ReplayRequestBody {
  operationName?: string
  variables?: Record<string, any>
}

interface ReplayEventPayload {
  data?: {
    payload?: {
      data?: {
        request?: {
          body?: ReplayRequestBody
        }
        response?: any
      }
    }
  }
}

type ReplayEvent = ReplayFrameEvent & ReplayEventPayload

const tracePropagationTargets = [
  HUBS_URL.LOCAL,
  HUBS_URL.DEVELOPMENT,
  HUBS_URL.STAGING,
  HUBS_URL.PRODUCTION,
]

const sentryClient = {
  value: null as ReturnType<typeof Sentry.init>,
}

const obfuscateValue = (value: unknown): any => {
  if (typeof value === 'string') {
    return value.replace(/\D/g, '*')
  }

  if (Array.isArray(value)) {
    return value.map(obfuscateValue)
  }

  if (value !== null && typeof value === 'object') {
    return Object.fromEntries(
      Object.entries(value).map(([k, v]) => [k, obfuscateValue(v)]),
    )
  }

  return value
}

const obfuscateGQLVariables = (graphqlVariables: Record<string, any>) => {
  return obfuscateValue(graphqlVariables)
}

const handleBeforeAddRecordingEvent = (event: ReplayEvent): ReplayEvent => {
  try {
    const graphqlVariables = event.data?.payload?.data?.request?.body?.variables

    if (graphqlVariables) {
      event.data.payload.data.request.body.variables =
        obfuscateGQLVariables(graphqlVariables)
    }

    delete event.data?.payload?.data?.response
  } catch (error) {
    Sentry.captureEvent({
      message: 'Error in beforeAddRecordingEvent function',
      extra: { error },
      level: 'error',
    })
  }

  return event
}

export const useSentry = (params: { browserTabId: string }) => {
  useEffect(() => {
    const { browserTabId } = params
    const dsn = process.env.SENTRY_DSN_FRONTEND
    const environment = process.env.ENVIRONMENT

    const reasonsToDisableConfig: [boolean, string][] = [
      [!sentryClient.value, '!sentryClient.value'],
      [!dsn, '!dsn'],
      [isLocalFn(), 'isLocal'],
      [isGHActionFn(), 'isGHAction'],
      // TODO: Fix to use isPreviewFn() - fails in codebuild build, but not FC build or local
      [process.env.IS_PREVIEW === 'true', 'isPreview'],
    ]

    const reasonsToDisable = reasonsToDisableConfig.filter(
      ([condition]) => condition,
    )

    if (reasonsToDisable.length > 0) {
      if (!isProductionFn()) {
        const reasons = reasonsToDisable.map(([, reason]) => reason).join(', ')
        // eslint-disable-next-line no-console
        console.warn(`Sentry disabled in ${environment}, reasons: ${reasons}`)
      }
      return
    }

    const webCommitHash = process.env.GIT_COMMIT_HASH
    const webCommitTime = process.env.GIT_COMMIT_TIME
    const deploymentSlot = process.env.DEPLOYMENT_SLOT
    const isFlightControl = process.env.FLIGHTCONTROL === 'true'

    sentryClient.value = Sentry.init({
      dsn,
      environment,
      initialScope(scope) {
        scope.setTag('commitTime', webCommitTime)
        scope.setTag('browserTabId', browserTabId)
        if (deploymentSlot) {
          scope.setTag('deploymentSlot', deploymentSlot)
        }
        if (isFlightControl) {
          scope.setTag('isFlightControl', isFlightControl)
        }
        return scope
      },
      release: webCommitHash,
      integrations: [
        Sentry.replayIntegration({
          networkDetailAllowUrls: ['/api/graphql'],
          networkCaptureBodies: true,
          beforeAddRecordingEvent: handleBeforeAddRecordingEvent,
        }),
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration(),
      ],
      tracePropagationTargets,
      tracesSampleRate: isProductionFn() ? 0.25 : 0,
      profilesSampleRate: isProductionFn() ? 0.25 : 0,
      // Capture Replay for no sessions
      replaysSessionSampleRate: 0,
      // PROD: Capture ALL/100% of sessions with an error
      replaysOnErrorSampleRate: isProductionFn() ? 1.0 : 0,
    })
  }, [])
}
