import { useCallback, useMemo, useState } from 'react'

import { useAuth } from 'src/Providers'

import { Client } from '../AliasSwitcherCell/AliasSwitcherCell'
import Button from '../Library/Button/Button'

interface AliasItemProps {
  client: Client
  selectAlias: (clientId: number) => void
}

const buttonBaseStyle = 'flex w-full items-center justify-between  text-left'
const buttonDefaultStyle =
  '!border-gray-800 bg-gray-800 hover:bg-gray-600 focus:bg-gray-600'

const AliasItem = (props: AliasItemProps) => {
  const { currentUser } = useAuth()
  const { client, selectAlias } = props
  const [isSelected, setIsSelected] = useState(false)

  const handleSelectedAlias = useCallback(() => {
    setIsSelected(true)
    selectAlias(client.id)
  }, [client.id, selectAlias])

  const buttonClassName = useMemo(
    () =>
      isSelected ? buttonBaseStyle : `${buttonBaseStyle} ${buttonDefaultStyle}`,
    [isSelected],
  )

  return (
    <li key={client.id}>
      <Button
        className={buttonClassName}
        color={isSelected ? 'primary' : 'inherit'}
        buttonDataTestId={`alias-switcher-${client.name.replace(' ', '-')}`}
        onClick={handleSelectedAlias}
      >
        <span>
          {client.id === currentUser.membershipData.clientId ? (
            <div className="flex flex-row items-end gap-x-2">
              <h1 className="text-2xl text-white">{client.name}</h1>
              <span className="text-lg text-green-500">Support Mode</span>
            </div>
          ) : (
            <h1 className="text-2xl text-white">{client.name}</h1>
          )}

          {client.status === 'INACTIVE' && (
            <span className="text-lg normal-case text-red-500">Inactive</span>
          )}

          {client.status === 'PAUSED' && (
            <span className="text-lg normal-case text-orange-400">Paused</span>
          )}

          {client.status === 'SUSPENDED' && (
            <span className="text-lg normal-case text-red-500">Suspended</span>
          )}
        </span>

        <img
          className="h-[50px]"
          height="50"
          width="auto"
          alt={client?.name}
          src={client.logoUrl}
        />
      </Button>
    </li>
  )
}

export default AliasItem
