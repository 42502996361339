import useRefreshClient from 'src/lib/hooks/useRefreshClient'

import Button from '../Library/Button'

const RefreshClient = () => {
  const { refreshClient } = useRefreshClient()

  return (
    <>
      {refreshClient && (
        <div key="refreshClient">
          <Button
            buttonDataTestId="sidebar-refresh-client-btn"
            onClick={() => window.location.reload()}
            className="text-md h-12 min-w-[0px] !rounded-none bg-orange-400 px-0 hover:bg-orange-500"
          >
            A new version of The Hub is available. Please refresh to continue.
          </Button>
        </div>
      )}
    </>
  )
}

export default RefreshClient
