import { useEffect, useState } from 'react'

import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid'

import Button from 'src/components/Library/Button'
import Modal from 'src/components/Modal/Modal'

interface SessionExpiredModalProps {
  isOpen: boolean
  title?: string
  description?: string
  buttonTestId?: string
  countdownSeconds?: number
}

const SessionExpiredModal = ({
  isOpen,
  title = 'Session Expired',
  description = 'Click the button to reload the page.',
  buttonTestId = 'reload-page-btn',
  countdownSeconds = 10,
}: SessionExpiredModalProps) => {
  const [countdown, setCountdown] = useState(countdownSeconds)

  const handleRefresh = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (!isOpen) {
      setCountdown(countdownSeconds)
      return
    }

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          handleRefresh()
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [isOpen, countdownSeconds])

  return (
    <Modal
      open={isOpen}
      title={title}
      onClose={() => {}}
      closeButtonVisible={false}
      backDropClickDisable={true}
      cancelButtonVisible={false}
      footerVisible={false}
      dialogClassName="max-w-[400px] !top-32"
    >
      <div className="flex items-center justify-center">
        <div className="py-6 text-center">
          <h1 className="text-xl font-medium text-slate-800">{title}</h1>
          <h2 className="text-md pt-4 font-medium text-slate-400">
            {description}
          </h2>
          <p className="mt-2 text-sm text-slate-500">
            Auto-refreshing in {countdown} seconds...
          </p>
          <Button
            buttonDataTestId={buttonTestId}
            className="my-6 min-w-[0px] items-center gap-2 px-0"
            onClick={handleRefresh}
          >
            <ArrowPathRoundedSquareIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
            Reload Page
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SessionExpiredModal
