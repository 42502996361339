import { useEffect } from 'react'

import { captureEvent } from '@sentry/browser'
import { CUSTOM_HTTP_HEADERS } from 'api/src/common/enums'
import { getUniqId } from 'api/src/common/utils'

const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME

export const getHubsApiUrl = () => {
  const apiUrl: string = globalThis.RWJS_API_URL ?? ''
  // Trim trailing slash
  const apiUrlClean = apiUrl.trim().replace(/\/$/, '')

  return apiUrlClean
}

const getString = (value: unknown) => {
  if (!value) {
    return null
  }
  if (typeof value === 'string') {
    return value
  }

  return null
}

const getRequestInit = (value: unknown) => {
  if (!value) {
    return null
  }

  if (typeof value === 'object') {
    const options = value as RequestInit
    if (!!options.method && !!options.headers) {
      return options
    }
  }

  return null
}

/**
 * This hook adds headers to fetch requests to identify the browser tab and the web request
 * NOTE: Only works for fetch, not Axios (XMLHttpRequest)
 */
const useFetchProxy = (params: { browserTabId: string }) => {
  useEffect(() => {
    const { browserTabId } = params
    const apiUrl = getHubsApiUrl()

    const fetchProxy = new Proxy(globalThis.fetch, {
      apply: (target, thisArg, args) => {
        try {
          const url = getString(args?.[0])
          const isApiUrl: boolean = url?.startsWith(apiUrl) ?? false

          if (isApiUrl && args[1]) {
            const options = getRequestInit(args[1])
            if (options) {
              const webRequestId = getUniqId()
              // Ensure headers object exists
              options.headers = options.headers || {}
              options.headers[CUSTOM_HTTP_HEADERS.BROWSER_TAB_ID] = browserTabId
              options.headers[CUSTOM_HTTP_HEADERS.GIT_COMMIT_HASH] =
                webCommitHash
              options.headers[CUSTOM_HTTP_HEADERS.GIT_COMMIT_TIME] =
                webCommitTime
              options.headers[CUSTOM_HTTP_HEADERS.WEB_REQUEST_ID] = webRequestId
            }
          }
        } catch (error) {
          // Swallow error - let's not break the app if this fails
          captureEvent({
            message: 'useFetchProxy failed',
            extra: { error },
          })
        }

        return Reflect.apply(target, thisArg, args)
      },
    })

    // Override fetch with our proxy
    const originalFetch = globalThis.fetch
    globalThis.fetch = fetchProxy

    return () => {
      // Restore original fetch on unmount
      globalThis.fetch = originalFetch
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, [])
}

export default useFetchProxy
