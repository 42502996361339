import { useEffect, useMemo, useState } from 'react'

import { LicenseManager } from 'ag-grid-enterprise'
import { getUniqId, loadDayjsExtensions } from 'api/src/common/utils'

import { initialAuth, resetAuth } from 'src/auth'
import CognitoTokenChecker from 'src/components/CognitoTokenChecker/CognitoTokenChecker'
import { AuthClient } from 'src/lib/authClients'
import { AuthClientContext } from 'src/lib/AuthContext'
import useFetchProxy from 'src/lib/hooks/useFetchProxy'
import { useIntercomLoad } from 'src/lib/hooks/UseIntercomLoad'
import { useSentry } from 'src/lib/hooks/UseSentry'
import Providers from 'src/Providers'
import Routes from 'src/Routes'

import 'highlight.js/styles/github.css'
import './styles/ejs2-icons.css'
import './styles/ejs2-rte-content.css'
import './styles/ejs2-tailwind.css'
import './styles/index.css'
import './styles/react-resizable.css'
import './styles/scaffold.css'

const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME
const deploymentSlot = process.env.DEPLOYMENT_SLOT
const browserTabId = getUniqId()
const agGridLicenseKey = process.env.AG_GRID_LICENSE_KEY

loadDayjsExtensions()

const App = () => {
  useIntercomLoad()
  useSentry({ browserTabId })
  useFetchProxy({ browserTabId })

  LicenseManager.setLicenseKey(agGridLicenseKey)

  const initAuth = useMemo(() => initialAuth, [])
  const [auth, _setAuth] = useState(initAuth)

  const setAuth = (authClient: AuthClient) => _setAuth(resetAuth(authClient))

  return (
    auth && (
      <>
        <div style={{ visibility: 'hidden', display: 'none' }}>
          <p>CommitId:{webCommitHash}</p>
          <p>CommitTime: {webCommitTime}</p>
          {deploymentSlot && <p>DeploymentSlot: {deploymentSlot}</p>}
        </div>
        <AuthClientContext.Provider value={[auth, setAuth]}>
          <Providers>
            <CognitoTokenChecker />
            <Routes />
          </Providers>
        </AuthClientContext.Provider>
      </>
    )
  )
}

export default App
